<template>
  <div>
    <Dialog
      @updated="
        (_) => {
          dialog = _;
          getCars();
        }
      "
      :dialog="dialog"
    />
    <div class="d-flex flex-column my-2">
      <v-row class="">
        <v-col cols="4">
          <label for="se">{{ $t('search') }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label for="comp">{{ $t('select-company') }}</label>
          <v-autocomplete
            class="mt-2"
            v-model="company"
            :items="companies"
            item-text="name"
            :label="$t('select-company')"
            item-value="id"
            :loading="loading"
            :no-data-text="$t('no-data')"
            solo
            return-object
            @change="getCars()"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-card class="transparent">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = !dialog" color="secondary">
            <v-icon class="mx-1">mdi-plus-circle</v-icon>
            Add veichel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
disable-pagination
            :loading="loading"
            :headers="headers"
            :items="cars"
            :no-data-text="$t('no-data')"
            :hide-default-footer="true"
          >
            <template #item.edit="{ item }">
              <div class="d-flex">
                <v-btn @click="editCar(item)" text large color="white">
                  <v-icon class="mx-2" small>mdi-pencil</v-icon>
                  Edit
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="d-flex justify-center">
            <v-pagination
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Dialog from "./Dialog.vue";
export default {
  components: { Dialog },
  data() {
    return {
      loading: false,
      options: {
        Search: "",
        CompanyId: "",
        PageIndex: 1,
        PageSize: 20
      },
      total: 0,
      companies: [],
      company: {},
      cars: [],
      headers: [
        {
          text: this.$t('name'),
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "No.", value: "number" },
        { text: "Type", value: "type" },
        { text: "Color", value: "color" },
        { text: "Manufacture Year", value: "yearManufacture" },
        { text: "", value: "edit", sortable: false },
      ],
      dialog: false,
    };
  },
  methods: {
    getCars() {
      this.loading = true;
      this.options.CompanyId = this.$store.state.company.id;
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null && val !== undefined) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http
        .get("/Cars" + filter.replace("?&", "?"))
        .then((cars) => {
          
          this.total = cars.data.count;
          this.cars = cars.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("UPDATE_SNACKBAR", true);
        });
    },
    editCar(item) {
      this.$store.commit("cars/updateCar", item);
      this.$store.commit("cars/toggleEdit", true);
      this.dialog = true;
    },
  },
  watch: {
    options: {
      handler() {
        this.getCars();
      },
      deep: true,
    },
  },
  async created() {
    this.$http
      .get("/Companies",{
          params:{
            OfficeId: this.$store.state.userInfo.office.id
          }
        })
      .then((res) => {
        this.companies = res.data.result;
        this.getCars();
      })
      .catch(() => {
        this.$store.commit("UPDATE_SNACKBAR", true);
      });
  },
};
</script>