<template>
  <div>
    <!-- use drawer -->
    <drawer :title="$t('warehouses')" :subtitle="$store.state.warehouses.warehouse.name" :items="drawer.items" icon="warehouse"> </drawer>
    <!-- use order -->
    <orders v-if="tab == 'orders'" />
    <!-- use products -->
    <products v-if="tab == 'products'" />
    <!-- use cars -->
    <cars v-if="tab == 'veichels'" />
    <!--use delivery -->
    <delivery v-if="tab == 'delivery'" />
  </div>
</template>
<script>
// import drawer comonent
import Drawer from "@/layouts/Drawer";
// import orders component
import Orders from "../accounts/orders/Orders";
// import products component
import Products from "../accounts/products/Products.vue";
// import cars component
import Cars from "@/views/dashoard/cars/Cars.vue";
// import delivery component
import Delivery from "@/views/dashoard/cars/Delivery.vue";
export default {
  components: {
    Drawer,
    Orders,
    Products,
    Cars,
    Delivery
  },
  data() {
    return {
      drawer: {
        items: [
          {
            title: this.$t('products'),
            icon: "mdi-account-network",
            route: "warehouses-products",
            access:true,
          },
          // {
          //   title: "Prepare orders",
          //   icon: "mdi-autorenew",
          //   route: "warehouses-orders",
          //   access: this.$service.hideFrom((v) => [v == 1])
          // },
          // {
          //   title: "Veichels",
          //   icon: "mdi-file-find",
          //   route: "warehouses-veichels",
          //   access: this.$service.hideFrom((v) => [v == 1])
          // },
          // {
          //   title: "Delivery",
          //   icon: "mdi-car",
          //   route: "warehouses-delivery",
          //   access: this.$service.hideFrom((v) => [v == 1])
          // },
        ],
      },
    };
  },

  computed: {
    tab() {
      return this.$route.path.split("/")[2];
    },
  },
  created() {
    if (this.tab == undefined || this.tab == "") {
      this.$router.push({
        name: this.drawer.items.filter((v) => v.access == true || v.access == undefined)[0].route,
      });
    }
  },
  beforeMount() {
    this.$store.commit("TOGGLE_DRAWER", true);
  },
};
</script>